import React from "react"
import Row from "./bootstrap/row"
import SponsorBox from "./sponsor-box"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"

import Microsoft from "../images/logos/microsoft.png"
import Poly from "../images/logos/poly.png"
import RingCentral from "../images/logos/ringcentral.png"
import Zoom from "../images/logos/zoom.png"

const HeadlineSponsors = ({gridColor}) => {

  return (
    <div style={{position: `relative`}}>
      <div style={{
        position: `absolute`,
        width: `100%`,
        height: `100%`,
        background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
        bottom: `-1rem`,
        left: `-1rem`,
        zIndex: `-1`,
      }} />
      <Row>
        <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="rgb(210, 52, 100)" fontColor="#fff" border="2px rgb(210, 52, 100) solid">Supported<br />By</SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.microsoft.com/en-gb" target="_blank"><img src={Microsoft} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.poly.com/gb/en" target="_blank"><img src={Poly} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.ringcentral.com/gb/en/" target="_blank"><img src={RingCentral} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><OutboundLink href="https://zoom.us/" target="_blank"><img src={Zoom} className="img-fluid p-2" /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="transparent" border="2px #fff solid" fontColor="#fff"><Link to="/partners/" style={{ color: `#fff` }}>And Many More...</Link></SponsorBox>
        </div>
      </Row>
    </div>
  
  )
}

HeadlineSponsors.defaultProps = {
  gridColor: `#d23464`
  // gridColor: `rgba(251,146,1,1)`
}

export default HeadlineSponsors
