import PropTypes from "prop-types"
import React from "react"

import * as partnerCarouselStyles from "./partner-carousel.module.css"

import cabinetOffice from "../images/logos/cabinet_office.png" // Cabinet Office
import CCS from "../images/logos/ccs.png" // Crown Commercial Service
import CDDO from "../images/logos/cddo.png" // CentralDigital and Data Office
import theOfficeOfGovernmentProperty from "../images/logos/the_office_of_government_property.png" // The Office of Government Property
import GDS from "../images/logos/government_digital_service.png" // Government Digital Service
import techUK from "../images/logos/tech_uk.png" // techUK
import COSLA from "../images/logos/cosla.png" // COSLA
import HEE from "../images/logos/nhs_health_education.png" // Health Education England
import ideasUK from "../images/logos/ideas_uk.png" // ideas UK
import CIPD from "../images/logos/cipd.png" // CIPD
import NHSBSA from "../images/logos/nhs_bsa.png" // NHS Business Services Authority
import BCC from "../images/logos/bcc.png" // Birmingham City Council
import NTU from "../images/logos/ntu.png" // Nottingham Trent University
import LBR from "../images/logos/lbr.png" // London Borough of Redbridge
import universityOfLeeds from "../images/logos/university-of-leeds.png" // University of Leeds
import BCS from "../images/logos/bcs.png" // British Computer Society
import PPMA from "../images/logos/ppma.png" // PPMA
import GPA from "../images/logos/gpa-v2.png" // Government Property Agency

const PartnerCarousel = ({ text }) => (
  <div className={partnerCarouselStyles.wrapper}>
    <div className={partnerCarouselStyles.carousel}>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={cabinetOffice} className="img-fluid p-2" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={CCS} className="img-fluid p-4" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={CDDO} className="img-fluid p-2" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={theOfficeOfGovernmentProperty} className="img-fluid p-4" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={GDS} className="img-fluid p-3" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={techUK} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={COSLA} className="img-fluid p-3" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={HEE} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={ideasUK} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={CIPD} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={NHSBSA} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={BCC} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={NTU} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={LBR} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={universityOfLeeds} className="img-fluid" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={BCS} className="img-fluid" style={{ padding: `2rem`}} />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={PPMA} className="img-fluid p-2" />
        </div>
        <div className={partnerCarouselStyles.carousel__item}>
            <img src={GPA} className="img-fluid p-4" />
        </div>
    </div>
  </div>
)

PartnerCarousel.propTypes = {
  text: PropTypes.string,
}

PartnerCarousel.defaultProps = {
  text: `Page Title`,
}

export default PartnerCarousel