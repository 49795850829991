import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"

import GPA from "../images/gpa-partner.png"
import GovNews from "../images/gnd-partner.png"
import Headline from "../images/headline.png"

const SupportBanner = ({children, bgColor, fontColor, padding, border}) => (
  <div className="row" style={{
    maxWidth: `35.5rem`,
    // maxWidth: `43rem`
  }}>
    <div className="col"><OutboundLink href="https://www.gov.uk/government/organisations/government-property-agency" target="_blank"><img src={GPA} className="img-fluid" /></OutboundLink></div>
    <div className="col"><OutboundLink href="https://www.govnewsdirect.com/" target="_blank"><img src={GovNews} className="img-fluid" /></OutboundLink></div>
    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Headline} className="img-fluid" /></OutboundLink></div>
  </div>
)

SupportBanner.defaultProps = {
  bgColor: `#fff`,
  fontColor: `#000`,
  padding: `0`,
  border: `0`,
}

export default SupportBanner
