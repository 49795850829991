import PropTypes from "prop-types"
import React from "react"

import HeaderTitle from "../components/header-title"
// import HeaderAddress from "../components/header-address"
import HeaderDate from "../components/header-date"
import HeaderSubtitle from "../components/header-subtitle"
import HeaderStrapline from "../components/header-strapline"
import PrimaryButton from "../components/primary-button"
import VenueImage from "./venue-image"
import BandsCircle from "./bands-circle"
import BandsRectangle from "./bands-rectangle"
import BannerSupportImage from "./banner-support-image"
import { Link } from "gatsby"
import HeadlineSponsors from "./headline-sponsors"
import PartnerCarousel from "../components/partner-carousel"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import SupportBanner from "./support-banner"

const MainBanner = ({ text }) => (
  <div style={{
    position: `relative`,
    marginTop: `50px`
  }}>
    <div className="main-banner">
      <HeaderTitle />
      <HeaderSubtitle />
      <br />
      <HeaderStrapline />
      <br />
      <HeaderDate text="March 2023" />
      {/* <p className="text-white">Entry Deadline Extended until Friday 9th December 2022</p> */}
      <br />
      {/* <HeaderAddress /> */}
      {/* <PrimaryButton to="/winners/" text="View the Winners of 2021" /> */}
      <PrimaryButton to="/winners/" text="View the Winners" />&nbsp;&nbsp;
      <Link to="/conference/" className="btn" style={{
        textTransform: `uppercase`,
        color: `#fff`,
        fontWeight: `bold`,
        border: `2px solid #fff`,
        padding: `0.6rem 1rem`,
        borderRadius: `0`
      }}>View the Conference</Link>
      {/* <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><BannerSupportImage style={{maxWidth: `35rem`, margin: `2rem -.5rem`}} /></OutboundLink> */}
      <div className="mt-5"><SupportBanner /></div>
      {/* <HeadlineSponsors /> */}
    </div>
    {/* <VenueImage className="d-none d-lg-block" style={{
      width: `470px`,
      position: `absolute`,
      top: `0`,
      right: `0`,
      // zIndex: `-2`,
    }} /> */}
    <div className="d-none d-lg-block" style={{
        position: `absolute`,
        top: `300px`,
        right: `130px`,
        width: `250px`,
        zIndex: `1000`
      }}>
      <PartnerCarousel></PartnerCarousel>
    </div>
    {/* <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 25%)`,
      width: `450px`,
      height: `450px`,
      borderRadius: `50%`,
      position: `absolute`,
      top: `20px`,
      right: `-150px`,
      zIndex: `-3`
    }} /> */}
    {/* <BandsCircle size="450px" style={{
      position: `absolute`,
      top: `-20px`,
      right: `-180px`,
      zIndex: `-1`
    }} /> */}
    <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 75%)`,
      width: `60px`,
      height: `60px`,
      borderRadius: `50%`,
      position: `absolute`,
      top: `100px`,
      right: `50px`,
      zIndex: `-3`
    }} />
    <BandsRectangle style={{
      position: `absolute`,
      bottom: `140px`,
      right: `0px`,
      width: `120px`,
      height: `26px`,
      zIndex: `-4`
    }} />
  </div>
)

MainBanner.propTypes = {
  text: PropTypes.string,
}

MainBanner.defaultProps = {
  text: ``,
}

export default MainBanner